<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>

            <!--excelY-->
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mr-1"
                @click="advanced_search_excel"
            >
              <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
              />
              <span class="align-middle">导入</span>
            </b-button>

          </b-col>

          <!-- Search -->
          <!-- Search -->
          <b-col
              cols="12"
              md="9"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  v-b-modal.changeWarehouseModal
              >
                <span class="align-middle">替换仓库</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="relationAndCreate"
              >
                <span class="align-middle">关联线上并退货</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="relationSalesOrder"
              >
                <span class="align-middle">关联销售订单</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-50"
                  @click="createReturnOrder('')"
              >
                <span class="align-middle">创建退货单</span>
              </b-button>

<!--              <b-button-->
<!--                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--                  variant="outline-success"-->
<!--                  class="mr-1"-->
<!--                  @click="relationSalesOrderOff"-->
<!--              >-->
<!--                <span class="align-middle">关联线下销售订单</span>-->
<!--              </b-button>-->

<!--              <b-button-->
<!--                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--                  variant="outline-success"-->
<!--                  class="mr-50"-->
<!--                  @click="createReturnOrder('Off')"-->
<!--              >-->
<!--                <span class="align-middle">创建线下退货单</span>-->

<!--              </b-button>-->

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-50"
                  @click="reload"
              >
                <span class="align-middle">清空勾选</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  v-b-modal.delete
                  class="mr-50"
                  v-if="user.role_id==14"
              >
                <span class="align-middle">删除数据</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  v-if="user.role_id==14"
                  @click="batchDelete"
              >
                <span class="align-middle">批量删除</span>
              </b-button>
            </div>
          </b-col>

        </b-row>
        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="外部商品ID:"
                    label-for="out_product_id"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.out_product_id"
                      name="out_product_id"
                      class="d-inline-block mr-1"
                      type="number"
                      size="sm"
                      placeholder="请输入商品ID"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="外部商品名称:"
                    label-for="out_product_name"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.out_product_name"
                      name="out_product_name"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="请输入商品名称"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="我方商品ID:"
                    label-for="product_id"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.product_id"
                      name="product_id"
                      class="d-inline-block mr-1"
                      type="number"
                      size="sm"
                      placeholder="请输入商品ID"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="我方商品名称:"
                    label-for="product_name"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.product_name"
                      name="product_name"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="请输入商品名称"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="excel编码:"
                    label-for="batchno"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.batchno"
                      name="batchno"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="请输入excel编码"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="关联销售订单:"
                    label-for="is_related"
                    label-size="sm"
                >
                  <v-select
                      id="is_related"
                      :options="is"
                      v-model="condition.is_related"
                      name="is_related"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="创建退货申请单:"
                    label-for="channel_name"
                    label-size="sm"
                >
                  <v-select
                      id="is_createorder"
                      :options="is"
                      v-model="condition.is_createorder"
                      name="is_createorder"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="数据来源:"
                    label-for="orderStatus"
                    label-size="sm"
                >
                  <v-select
                      id="source"
                      :options="source_option"
                      v-model="condition.source"
                      name="source"
                      class="select-size-sm"
                      placeholder="请选择数据来源"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="类型:"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      id="type"
                      :options="type_option"
                      v-model="condition.type"
                      name="source"
                      class="select-size-sm"
                      placeholder="请选择类型"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="创建人"
                    type="input"
                    v-on:change="fromChildren($event,['creatorName','creator'])"
                    :params="['creatorName','creator']"
                    :value="condition.creatorName"
                    modalName="创建人"
                    placeholder="点击选择创建人"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="主体"
                    type="input"
                    v-on:change="fromChildren($event,['searchOurCompanyName','searchOurCompanyId'])"
                    :params="['searchOurCompanyName','searchOurCompanyId']"
                    :value="condition.searchOurCompanyName"
                    modalName="销售主体"
                    placeholder="点击选择主体"
                >
                </xy-input-button>
              </b-col>

              <b-col md="4">
                <xy-input-button label="发生日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['out_service_date'])"
                                 :params="['out_service_date']"
                                 :value="condition.out_service_date"
                >
                </xy-input-button>
              </b-col>
              <b-col md="4">
                <xy-input-button label="销售日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['out_sales_time'])"
                                 :params="['out_sales_time']"
                                 :value="condition.out_sales_time"
                >
                </xy-input-button>
              </b-col>
              <b-col md="4">
                <xy-input-button label="创建日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['create_time'])"
                                 :params="['create_time']"
                                 :value="condition.create_time"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="终端客户"
                    type="input"
                    v-on:change="fromChildren($event,['channelName','channelId'])"
                    :params="['channelName','channelId']"
                    :value="condition.channelName"
                    modalName="终端客户"
                    placeholder="点击选择终端客户"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
                  class="mt-1"
              >
                <!--                <xy-input-button-->
                <!--                    label="平台"-->
                <!--                    type="input"-->
                <!--                    v-on:change="fromChildren($event,['companyName','company_id'])"-->
                <!--                    :params="['companyName','company_id']"-->
                <!--                    :value="condition.companyName"-->
                <!--                    modalName="渠道方"-->
                <!--                    placeholder="点击选择渠道方"-->
                <!--                >-->
                <!--                </xy-input-button>-->
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="渠道方:"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      :options="channelArray"
                      :clearable="true"
                      class="select-size-sm"
                      v-model="condition.companyName"
                      @input="changeSelect($event,'channel')"
                      placeholder="请选择渠道方"
                  />
                </b-form-group>

              </b-col>

              <b-col
                  cols="12"
                  md="4"
                  class="mt-1"
              >
                <!--                <xy-input-button-->
                <!--                    label="店铺"-->
                <!--                    type="input"-->
                <!--                    v-on:change="fromChildren($event,['storeSearchName','storeSearchId'])"-->
                <!--                    :params="['storeSearchName','storeSearchId']"-->
                <!--                    :value="condition.storeSearchName"-->
                <!--                    modalName="店铺"-->
                <!--                    placeholder="点击选择店铺"-->
                <!--                >-->
                <!--                </xy-input-button>-->
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="店铺:"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      v-model="condition.storeArray"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="label"
                      :options="storeArray"
                      class="select-size-sm"
                      @input="changeSelect($event,'store')"
                      placeholder="请选择店铺"
                  />
                </b-form-group>

              </b-col>

              <b-col
                  cols="12"
                  md="4"
                  class="mt-1"
              >
                <!--                <xy-input-button-->
                <!--                    label="仓库"-->
                <!--                    type="input"-->
                <!--                    v-on:change="fromChildren($event,['searchWarehouseName','searchWarehouseId'])"-->
                <!--                    :params="['searchWarehouseName','searchWarehouseId']"-->
                <!--                    :value="condition.searchWarehouseName"-->
                <!--                    modalName="仓库"-->
                <!--                    placeholder="点击选择仓库"-->
                <!--                >-->
                <!--                </xy-input-button>-->
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库:"
                    label-for="type"
                    label-size="sm"
                >
                  <v-select
                      v-model="condition.warehouseArray"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="label"
                      :options="warehouseArray"
                      class="select-size-sm"
                      @input="changeSelect($event,'warehouse')"
                      placeholder="请选择仓库"
                  />
                </b-form-group>

              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="Export"
                >
                  <span class="align-right">导出</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <!--excelY-->
        <b-card
            v-show="advanced_search_modal_excel"
        >
          <b-form

          >
            <b-row>
              <!--平台选择-->
              <!--              <b-col-->
              <!--                  cols="12"-->
              <!--                  md="4"-->
              <!--              >-->
              <!--                <b-form-group-->
              <!--                    label-cols="3"-->
              <!--                    label-cols-lg="3"-->
              <!--                    label="平台选择:"-->
              <!--                    label-for="channel_name"-->
              <!--                    label-size="sm"-->
              <!--                    class="required"-->
              <!--                >-->
              <!--                  <b-form-input-->
              <!--                      id="company_id_excel"-->
              <!--                      size="sm"-->
              <!--                      v-model="companyNameExcel"-->
              <!--                      name="company_id_excel"-->
              <!--                      @click="showSingleModal('平台')"-->
              <!--                      readonly-->
              <!--                      placeholder="点击搜索平台"-->
              <!--                  />-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->

              <!--              &lt;!&ndash;店铺&ndash;&gt;-->
              <!--              <b-col-->
              <!--                  cols="12"-->
              <!--                  md="6"-->
              <!--              >-->
              <!--                <b-form-group-->
              <!--                    label-cols="3"-->
              <!--                    label-cols-lg="3"-->
              <!--                    label="店铺选择:"-->
              <!--                    label-for="store_name"-->
              <!--                    label-size="sm"-->
              <!--                >-->
              <!--                  <b-form-input-->
              <!--                      id="store_Name"-->
              <!--                      size="sm"-->
              <!--                      v-model="storeName"-->
              <!--                      name="storeName"-->
              <!--                      @click="showSingleModal('店铺')"-->
              <!--                      readonly-->
              <!--                      placeholder="点击搜索店铺"-->
              <!--                  />-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->
              <!--excel-->

              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="相关附件"
                    label-for="attachments_ids"
                    label-size="sm"
                    class="mb-1"
                >

                  <!-- :attachment_id="'attachments'"
                              :id="data.item.attachments"
                              :object_type="'allot_outbound'"
                              :object_id="0"-->

                  <attachment-upload :theme="'files'"
                                     :object_type="'excel'"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>

              <!--导入关闭-->
              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="searchByConditionExcel"
                    v-if="show"
                >
                  <span class="align-right">导入</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="importOff"
                    v-if="show"
                >
                  <span class="align-right">线下导入</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search_excel"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-row>
          <b-col
              cols="12"
              md="6"
              class="mt-1"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
          striped
          hover
          table-variant="info"
          head-variant="light"
          small
      >

        <!-- Columns -->
        <template v-slot:head(#)="data">
          <xy-checkbox
              :checked="checked"
              :allChecked.sync="allChecked"
              :indeterminate="indeterminate"
              :isCheckedObj.sync="isCheckedObj"
              :table="table"
              type="head"
              :row="data"
          />
        </template>
        <template v-slot:cell(#)="row">
          <xy-checkbox
              :checked="checked"
              :allChecked.sync="allChecked"
              :indeterminate="indeterminate"
              :isCheckedObj="isCheckedObj"
              :table="table"
              type="row"
              :row="row"
              :rowChecked.sync="isCheckedObj['index'+row.item.id]"
          />
        </template>

        <template #cell(service_id)="data">
          <div style="width: 80px"></div>
          {{ data.item.service_id }}
          <feather-icon
              icon="EyeIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              'orderId：'+data.item.order_id+
              '<br>'+'itemId：'+data.item.item_id+
              '<br>'+'我方系统商品id：'+data.item.product_id+
              '<br>'+'添加时间：'+toTime(data.item.create_time)+
              '<br>'+'添加人：'+getCodeLabel('user', data.item.creator)+
              '<br>'+'excel编码：'+data.item.batchno+
              '</div>'"
              variant="success"
          />
        </template>

        <!-- Column: Type -->
        <template #cell(out_sales_time)="data">
          <div style="width: 150px">
            {{ data.item.type == 1 ? "" : "售卖:" + toDate(data.item.out_sales_time) }}
            <br>
            售后:{{ toDate(data.item.out_service_date) }}
          </div>
        </template>

        <template #cell(type)="data">
          <!--          {{data.item.out_sales_time}}-->
          {{ data.item.type == 1 ? "线下" : "线上" }}
        </template>

        <template #cell(out_service_date)="data">
          <!--          {{data.item.out_sales_time}}-->
          {{ toDate(data.item.out_service_date) }}
        </template>

        <template #cell(out_product_id)="data">
          <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="sm"
              label="id:"
              label-for="input-sm"
          >
            <b-form-input
                v-model="data.item.out_product_id"
                name="orderNo"
                class="d-inline-block mr-1"
                size="sm"
                type="number"
                :readonly="data.item.is_related!==0||data.item.is_createorder!==0"
            />
          </b-form-group>
          <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="sm"
              label="名称:"
              label-for="input-sm"
          >
            {{ data.item.out_product_name }}
          </b-form-group>
        </template>

        <template #cell(product_name)="data">
          69码:{{ data.item.ext.code }}
          <br>
          名称:{{ data.item.product_name }}
        </template>


        <template #cell(memo)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
            <feather-icon icon="EditIcon" @click="showRemark(data.item)"/>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(company_id)="data">
          <div style="width: 150px;">
            平台:#[{{ data.item.company_id }}]{{ getCodeLabel("company", data.item.company_id) }}
            <br>
            店铺:{{ getCodeLabel("store", data.item.store_id) }}
            <br>
            {{
              data.item.type === 1 ? '终端客户:' + getCodeLabel("company", data.item.channel_id) : '主体:' + getCodeLabel("company", data.item.ourcompany_id)
            }}
          </div>
        </template>

        <template #cell(store_id)="data">
          #[{{ data.item.store_id }}]{{ getCodeLabel("store", data.item.store_id) }}
        </template>

        <template #cell(is_related)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.is_related==0"
          />
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-else
          />
        </template>

        <template #cell(is_createorder)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.is_createorder==0"
          />
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-else
          />
        </template>

        <template #cell(from_warehouse_id)="data">
          <div style="width: 150px;">
            出仓:{{ data.item.fromWarehouseName }}
            <br>
            入仓:{{ getCodeLabel("warehouse", data.item.to_warehouse_id) }}
          </div>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <template #cell(action)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
                @click="showSingleModal('商品',data.item)"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">手动匹配商品</span>
            </b-dropdown-item>
            <b-dropdown-item
                @click="save(data.item)"
                v-if="data.item.is_related===0&&data.item.is_createorder===0"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">保存</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >

          <company-select
              ref="companySelect" :companyType="companyType"
              v-if="modalName ==='渠道方'||modalName ==='平台'||modalName ==='主体' "
          >
          </company-select>

          <!-- :userDepartment="userDepartment"  判断负责人所属部门-->
          <user-select
              ref="userSelect" v-else-if="modalName === '创建人' "
          >
          </user-select>

          <warehouse-list
              ref="warehouseSelect" :storeIds="storeIds" v-else-if="modalName === '出库仓库'||modalName === '入库仓库'  "
          >
          </warehouse-list>

          <store-list-select
              ref="storeSelect" v-else-if="modalName === '店铺'||modalName==='搜索店铺' "
          >
          </store-list-select>

          <product-list
              ref="productSelect" v-else-if="modalName === '商品' "
          >
          </product-list>


        </b-modal>

        <b-modal
            id="modal-remark"
            ok-only
            ok-title="确认"
            @ok="saveWarehouse"
            cancel-title="取消"
            centered
            size="lg"
            title="弹窗"
            ref="Modal"
        >
          <b-row>
            <!--            <b-col cols="12" md="6">-->
            <!--              <b-form-group-->
            <!--                  label-cols="3"-->
            <!--                  label-cols-lg="3"-->
            <!--                  label="出库仓库:"-->
            <!--                  label-for="out_warehouse"-->
            <!--                  label-size="sm"-->
            <!--                  class="required"-->
            <!--              >-->
            <!--                <b-form-input-->
            <!--                    id="out_warehouse"-->
            <!--                    size="sm"-->
            <!--                    v-model="outWarehouseName"-->
            <!--                    name="out_warehouse"-->
            <!--                    @click="showSingleModal('出库仓库')"-->
            <!--                    readonly-->
            <!--                    placeholder="点击搜索出库仓库"-->
            <!--                />-->
            <!--              </b-form-group>-->

            <!--            </b-col>-->
            <b-col cols="12" md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="入库仓库:"
                  label-for="in_warehouse"
                  label-size="sm"
              >
                <b-form-input
                    id="in_warehouse"
                    size="sm"
                    name="in_warehouse"
                    @click="showSingleModal('入库仓库')"
                    readonly
                    placeholder="点击搜索入库仓库"
                    disabled
                />
              </b-form-group>

            </b-col>
          </b-row>

          <!--          <store-list-select-->
          <!--              ref="storeSelect" v-if="modalName === '店铺' "-->
          <!--          >-->
          <!--          </store-list-select>-->

        </b-modal>

        <b-modal
            id="modal-remark"
            ok-only
            ok-title="确认"
            @ok="saveRemark"
            cancel-title="取消"
            centered
            size="lg"
            title="修改备注"
            ref="remarkModal"
        >
          <b-card :header="`编号：${selectedItem.service_id}`">
            <b-form-textarea
                id="textarea"
                v-model="remarkInfo"
                rows="3"
                max-rows="6"
            />
          </b-card>
        </b-modal>

        <b-modal
            id="delete"
            ok-only
            ok-title="确认"
            @ok="deleteData()"
            cancel-title="取消"
            centered
            size="lg"
            title="删除窗口"
            ref="delete"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="excel编码:"
              label-for="channel_order_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="batchno"
                size="sm"
                v-model="batchnos"
                placeholder="请输入excel编码"
            />
          </b-form-group>
        </b-modal>

        <!-- 加载modal -->
        <b-modal
            id="loadingModal"
            centered
            hide-header
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            ref="loadingModal"
        >
          <p class="my-4">单据创建中，请勿关闭浏览器!</p>
        </b-modal>

        <!-- 加载modal -->
        <b-modal
            id="changeWarehouseModal"
            ok-only
            ok-title="确认"
            @ok="changeWarehouse()"
            cancel-title="取消"
            centered
            size="lg"
            title="替换仓库"
            ref="changeWarehouseModal"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="选择仓库:"
              label-for="orderStatus"
              label-size="sm"
          >
            <v-select
                :options="getCodeOptions('warehouse')"
                v-model="changeWarehouses"
                name="source"
                class="select-size-sm"
                placeholder="请选择数据来源"
            />
          </b-form-group>
        </b-modal>


      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, watch} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import serviceorderUseList from './serviceorderUseList'
import serviceorderStore from './serviceorderStore'
import flatPickr from 'vue-flatpickr-component'
import CompanySelect from "../company/CompanySelect"
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import Ripple from "vue-ripple-directive";
import WarehouseList from "@/views/apps/warehouse/warehouse-modal/WarehouseList";
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import {useToast} from "vue-toastification/composition";
import axios from "@/libs/axios";
import StoreListSelect from '@/views/apps/store/StoreListSelect'
import serviceorder from "@/router/routes/apps/serviceorder";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {useRouter} from "@core/utils/utils";
import ProductList from "@/views/apps/product/product-datasales/ProductSelect";
import {getUserData} from "@/auth/utils";
import XyInputButton from "@/views/components/xy/XyInputButton";
import XyCheckbox from "@/views/components/xy/XyCheckbox";
import storeStore from "@/views/apps/store/storeStore";


export default {
  components: {
    XyCheckbox,
    XyInputButton,
    ProductList,
    WarehouseList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    flatPickr,
    CompanySelect,
    UserSelect,
    StoreListSelect,
    serviceorder,
    ToastificationContent,
    AttachmentUpload,
    warehouseStore,
    useToast,
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('serviceorder/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    showRemark(item) {
      this.selectedItem = item
      this.remarkInfo = item.memo
      this.$refs['remarkModal'].show()
    },
    resetCondition() {
      this.condition = {}
      this.reload()
      this.channelArray = []
      this.edit(this)
    },
    showSingleModal(modalName, data) {
      if (modalName === '渠道方' || modalName === '平台') {
        this.companyType = 'ChannelCustomer'
      } else if (modalName === '主体') {
        this.companyType = 'Group'
      } else if (modalName === '店铺') {
        this.companyType = 'Store'
      }
      this.modalName = modalName
      this.$refs['singleModal'].show()
      if (modalName === '商品') {
        this.outProductId = data.out_product_id
        this.soStoreId = data.store_id
        this.related = data.is_related
        this.create = data.is_createorder
      }

    },

    onSelectSingle() {
      let data = {}
      // this.$refs.itemList.refetchData();
      switch (this.modalName) {
        case '出库仓库': {
          data = this.$refs.warehouseSelect.getSelected()
          if (data.length > 0) {
            this.outWarehouseName = data[0].warehouse_name;
            this.outWarehouseId = data[0].warehouse_id;
          }
          break;
        }
        case '入库仓库': {
          data = this.$refs.warehouseSelect.getSelected()
          if (data.length > 0) {
            this.inWarehouseName = data[0].warehouse_name;
            this.inWarehouseId = data[0].warehouse_id;
            this.outWarehouseName = data[0].warehouse_name;
            this.outWarehouseId = data[0].warehouse_id;
          }
          break;
        }
          /*excelY*/
        case '平台': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.companyNameExcel = data[0].company_name;
            this.companyId = data[0].company_id;
          }
          break
        }
        case '店铺': {
          data = this.$refs.storeSelect.getSelected()
          if (data.length > 0) {
            this.storeName = data[0].store_name;
            this.storeId = data[0].store_id;
          }
          break;
        }
        case '搜索店铺': {
          data = this.$refs.storeSelect.getSelected()
          if (data.length > 0) {
            this.storeSearchName = data[0].store_name;
            this.storeSearchId = data[0].store_id;
          }
          break;
        }
        case '商品': {
          data = this.$refs.productSelect.getSelected()
          if (data.length > 0) {
            if (this.related == 1) {
              alert("此数据已经做过关联,关联失败！")
              return false
            }
            if (this.create == 1) {
              alert("此数据已经创建过退货单,关联失败！")
              return false
            }
            alert("关联时间较长,请稍等！")
            store.dispatch('serviceorder/changeProductId', {
              outProductId: this.outProductId,
              storeId: this.soStoreId,
              productId: data[0].id,
              productName: data[0].name
            }).then(res => {
              if (res.data.code == 0) {
                alert("关联成功")
              } else {
                alert("关联失败")
              }
              this.reload()
            })
          }
          break;
        }
        default: {
          //statements;
          break
        }
      }
    },
    showWarehouse() {
      this.$refs['warehouseSelect'].show()
    },
  },
  data() {
    return {
      advanced_search_modal: false,
      advanced_search_modal_excel: false,
      source_option: [{"label": '数据导入', code: 1}, {"label": '平台接口', code: 2}],
      type_option: [{"label": '线上', code: 1}, {"label": '线下', code: 2}],
      is: [{"label": '否', code: 0}, {"label": '是', code: 1}],
      creatorName: '',
      companyName: '',
      companyNameExcel: '',
      excelSqlId: '',
      storeName: '',
      modalName: '',
      companyType: '',
      storeId: '',
      companyId: '',

      outWarehouseName: '',
      outWarehouseId: 0,
      inWarehouseName: '',
      inWarehouseId: 0,
      checked: [],

      allChecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],

      storeIds: '',
      storeSearchName: '',
      searchOurCompanyName: '',
      outProductId: '',
      soStoreId: '',
      related: 0,
      create: 0,

      show: true,
      selectedItem: {},
      remarkInfo: null,

      batchnos: '',
      user: {},
      off: '',

      channelArray: [],
      storeArray: [],
      warehouseArray: [],

      changeWarehouses: {},

    }

  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('serviceorder')) store.registerModule('serviceorder', serviceorderStore)
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)


    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('serviceorder')) store.unregisterModule('serviceorder')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('store')) store.unregisterModule('store')

    })

    const searchByCondition = function () {
      this.reload();
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    /*excelY*/
    const advanced_search_excel = function () {
      this.advanced_search_modal_excel = this.advanced_search_modal_excel ? false : true;
    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }

    const relationSalesOrder = function () {
      if (this.checked.length === 0) {
        toast.error("请选择售后明细!")
        return false
      }
      toast.success("关联时间较长，请稍后!")
      store.dispatch('serviceorder/relationSalesOrder', {checked: JSON.stringify(this.checked)}).then(res => {
        if (res.data.data !== "success") {
          toast.error(res.data.data)
        } else {
          toast.success("关联成功")
        }
        this.reload()
      })
    }

    const relationSalesOrderOff = function () {
      if (this.checked.length === 0) {
        toast.error("请选择售后明细!")
        return false
      }
      toast.success("关联时间较长，请稍后!")
      store.dispatch('serviceorder/relationSalesOrderOff', {checked: JSON.stringify(this.checked)}).then(res => {
        if (res.data.data !== "success") {
          toast.error(res.data.data)
        } else {
          toast.success("关联成功")
        }
        this.reload()
      })
    }

    const createReturnOrder = function (params) {
      this.outWarehouseName = ''
      this.outWarehouseId = 0
      this.inWarehouseName = ''
      this.inWarehouseId = 0
      this.off = params

      if (this.checked.length === 0) {
        toast.error("请选择售后明细!")
        return false
      }

      store.dispatch('serviceorder/createReturnOrder', {checked: JSON.stringify(this.checked)}).then(res => {
        if (res.data.code == 0) {
          this.storeIds = res.data.data.store_id
          this.$refs['Modal'].show()
          this.outWarehouseName = getCodeLabel("warehouse", this.checked[0].to_warehouse_id)
          this.inWarehouseName = getCodeLabel("warehouse", this.checked[0].to_warehouse_id)
          this.outWarehouseId = this.checked[0].to_warehouse_id
          this.inWarehouseId = this.checked[0].to_warehouse_id
          //如所选店铺关联仓库只有一个那系统自动选择出库仓库
          // axios.get('api/warehouse/search', {
          //   params: {store_ids: ':' + this.checked[0].store_id + ','}
          // })
          //     .then(res => {
          //       let list = res.data.data.list
          //       if (list.length == 1) {
          //         this.outWarehouseName = list[0].warehouse_name
          //         this.inWarehouseName = list[0].warehouse_name
          //         this.outWarehouseId = list[0].warehouse_id
          //         this.inWarehouseId = list[0].warehouse_id
          //       }
          //     })

        } else {
          toast.error(res.data.data)
        }

      })

    }

    const saveWarehouse = function () {
      this.$refs['loadingModal'].show()
      if (this.outWarehouseId == 0) {
        toast.error("请选择出库仓库")
        this.$refs['loadingModal'].hide()
        return false
      }
      if (this.inWarehouseId == 0) {
        toast.error("请选择入库仓库")
        this.$refs['loadingModal'].hide()
        return false
      }
      toast.success("创建时间较长，请稍后!")
      store.dispatch('serviceorder/createSalesReturnOutbound' + this.off, {
        checked: JSON.stringify(this.checked),
        outwarehouse: this.outWarehouseId,
        inwarehouse: this.inWarehouseId,
        inwarehousename: this.inWarehouseName
      }).then(res => {
        if (res.data.data !== "success") {
          toast.error(res.data.data)
          this.$refs['loadingModal'].hide()
        } else {
          toast.success("创建成功")
          this.$refs['loadingModal'].hide()
        }
        this.reload();
      })

    }

    const searchByConditionExcel = function () {
      this.show = false

      const params = {
        storeId: this.storeId,
        companyId: this.companyId,
        excelSqlId: this.excelSqlId,
      }
      // if (this.companyId == null || this.companyId == "") {
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: '请选择平台',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger',
      //     },
      //   })
      //   return
      // } else {
      //
      // }
      store.dispatch('serviceorder/export', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code == 0) {
              toast.success(data)
              this.reload();
            } else {
              toast.error(data)
            }
            this.show = true
          })
    }

    const importOff = function () {
      this.show = false
      const params = {
        storeId: this.storeId,
        companyId: this.companyId,
        excelSqlId: this.excelSqlId,
      }

      store.dispatch('serviceorder/exportOff', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code == 0) {
              toast.success(data)
              this.reload();
            } else {
              toast.error(data)
            }
            this.show = true
          })
    }

    const reload = function () {
      this.checked.forEach(element => {
        this.isCheckedObj['index' + element.id] = false;
      });
      this.checked = []
      this.indeterminate = false
      this.allChecked = false
      refetchData()
    }

    const saveRemark = function () {
      this.selectedItem.memo = this.remarkInfo
      store.dispatch('serviceorder/save', this.selectedItem).then(res => {
        if (res.data.code === 0) {
          toast.success("修改成功")
        } else {
          toast.error("修改失败")
        }
        refetchData()
      })
    }

    const deleteData = function () {
      if (this.batchnos == "" || this.batchnos == undefined) {
        toast.error("请输入excel编码")
        return false
      }
      if (confirm("确定删除吗?")) {
        store.dispatch('serviceorder/delete', {batchno: this.batchnos}).then(res => {
          if (res.data.code === 0) {
            toast.success("删除成功")
          } else {
            toast.error(res.data.data)
          }
          refetchData()
        })
      }
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const save = function (params) {
      store.dispatch('serviceorder/save', params).then(res => {
        if (res.data.code === 0) {
          toast.success('数据已保存!')
          refetchData()
        } else {
          toast.error('保存失败!')
        }

      })
    }

    const batchDelete = function () {
      if (this.checked.length === 0) {
        toast.error("请选择售后明细!")
        return false
      }
      if (confirm("确定批量删除吗?")) {
        store.dispatch('serviceorder/batchDelete', {checked: JSON.stringify(this.checked)}).then(res => {
          if (res.data.code == 0) {
            toast.success("删除成功")
          } else {
            toast.error(res.data.data)
          }
          this.reload()
        })
      }
    }

    const Export = function () {
      if (confirm("确定导出吗?")) {
        const condition = this.condition
        const storeArrays = []
        const warehouseArrays = []
        const storeArray = this.condition.storeArray
        const warehouseArray = this.condition.warehouseArray
        if (!isEmpty(storeArray)) {
          for (let i = 0; i < storeArray.length; i++) {
            storeArrays.push(storeArray[i].value)
          }
        }
        if (!isEmpty(warehouseArray)) {
          for (let i = 0; i < warehouseArray.length; i++) {
            warehouseArrays.push(warehouseArray[i].value)
          }
        }
        axios({
              url: '/api/exportexcelfile/createServiceOrderExcel',
              method: 'get',
              params: {
                company_id: condition.companyName == null ? null : condition.companyName.value,
                channel_id: condition.channelId,
                source: condition.source == null ? null : condition.source.code,
                types: condition.type == null ? null : condition.type.code,
                outServiceDate: condition.out_service_date,
                outSalesTime: condition.out_sales_time,
                createTimes: condition.create_time,
                out_product_id: condition.out_product_id,
                out_product_name: condition.out_product_name,
                product_id: condition.product_id,
                product_name: condition.product_name,
                is_related: condition.is_related == null ? null : condition.is_related.code,
                is_createorder: condition.is_createorder == null ? null : condition.is_createorder.code,
                creator: condition.creator,
                ourcompany_id: condition.searchOurCompanyId,
                batchno: condition.batchno,
                storeIds: storeArrays.join(','),
                warehouseIds: warehouseArrays.join(','),
              },
              responseType: 'blob',
            }
        ).then(res => {
          toast.success("导出成功")
          let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
          let filename = "退货数据-" + new Date().getTime();
          /*-------------*/
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(data);
          link.setAttribute('download', filename + '.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      }
    }

    const relationAndCreate = function () {
      this.$refs['loadingModal'].show()
      if (this.checked.length === 0) {
        toast.error("请选择售后明细!")
        this.$refs['loadingModal'].hide()
        return false
      }
      toast.success("关联时间较长，请稍后!")
      store.dispatch('serviceorder/relationSalesOrderAndCreateSalesReturnOutbound', {checked: JSON.stringify(this.checked)}).then(res => {
        if (res.data.data !== "success") {
          toast.error(res.data.data)
        } else {
          toast.success("关联成功")
        }
        this.$refs['loadingModal'].hide()
        this.reload()
      })
    }

    const edit = function (_this) {
      axios.get('/api/company/list', {
        params: {
          company_type: 1
        }
      })
          .then(function (res) {
            const a = res.data.data.list
            for (let i = 0; i < a.length; i++) {
              _this.channelArray.push({
                'key': i, "label": a[i].company_name, "value": a[i].company_id
              })
            }
          })
      _this.storeArray = getCodeOptions('store')
      _this.warehouseArray = getCodeOptions('warehouse')
    }

    const changeSelect = function (event, params) {
      if (params === 'channel') {
        this.storeArray = []
        this.condition.storeArray = []
        this.condition.warehouseArray = []
        store.dispatch('store/search', {company_id: isEmpty(this.condition.companyName) ? null : this.condition.companyName.value})
            .then(response => {
              let data = response.data.data.list
              for (let i = 0; i < data.length; i++) {
                this.storeArray.push({'key': i, "label": data[i].store_name, "value": data[i].store_id})
              }
            })
        if (isEmpty(this.condition.companyName)) {
          this.warehouseArray = getCodeOptions('warehouse')
        }
      }
      if (params === 'store') {
        this.warehouseArray = []
        const array = []
        const storeArray = this.condition.storeArray
        for (let i = 0; i < storeArray.length; i++) {
          array.push(storeArray[i].value)
        }
        store.dispatch('warehouse/findWareHouseIds', {storeIds: array.join(',')})
            .then(response => {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                this.warehouseArray.push({'key': i, "label": data[i].warehouse_name, "value": data[i].warehouse_id})
              }
            })
      }
      this.$forceUpdate()
    }

    const changeWarehouse = function () {
      if (this.checked.length === 0) {
        toast.error("请选择售后明细!")
        return false
      }
      if (isEmpty(this.changeWarehouses)) {
        toast.error("请选择仓库!")
        return false
      }
      store.dispatch('serviceorder/changeWarehouse',
          {
            checked: JSON.stringify(this.checked),
            warehouseId: this.changeWarehouses.value
          }).then(res => {
        if (res.data.code == 0) {
          toast.success("修改成功")
        } else {
          toast.error(res.data.data)
        }
        this.reload()
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      // UI
    } = serviceorderUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      // UI
      advanced_search,
      advanced_search_excel,
      relationSalesOrder,
      createReturnOrder,
      saveWarehouse,
      searchByCondition,
      toTime,
      toDate,
      onUploaded,
      searchByConditionExcel,
      reload,
      saveRemark,
      deleteData,
      fromChildren,
      save,
      batchDelete,
      importOff,
      relationSalesOrderOff,
      Export,
      relationAndCreate,

      condition,
      edit,
      changeSelect,
      changeWarehouse,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
    this.edit(this)
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
