import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCodeLabel, isEmpty} from "@core/utils/filter";

export default function serviceorderUseList() {
    // Use toast
    const toast = useToast()

    const refListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {key: '#', label: '#'},
        {key: 'service_id', label: 'ID', sortable: true},
        {key: 'company_id', label: '渠道', sortable: true},
        {key: 'type', label: '类型', sortable: true},
        // { key: 'store_id', label: '店铺', sortable: true },
        // { key: 'ourcompany_id', label: '主体', sortable: true },
        // { key: 'out_order_id', label: '外部订单ID', sortable: true },
        // { key: 'out_service_order_id', label: '外部售后订单编号', sortable: true },
        // { key: 'out_service_date', label: '外部日期', sortable: true },
        {key: 'out_sales_time', label: '外部日期', sortable: true},
        {key: 'out_product_id', label: '外部商品', sortable: true},
        // { key: 'out_product_name', label: '外部商品名称', sortable: true },
        // { key: 'out_product_rule', label: '外部商品规格', sortable: true },
        {key: 'reason', label: '售后', sortable: true},
        {key: 'total_amount', label: '总金额', sortable: true},
        {key: 'total_qty', label: '数量', sortable: true},
        // { key: 'source', label: '来源 1数据导入  2平台接口', sortable: true },
        // { key: 'product_id', label: '我方系统商品id', sortable: true },
        {key: 'product_name', label: '我方商品名称', sortable: true},
        // { key: 'order_id', label: 'order_id', sortable: true },
        // { key: 'item_id', label: 'item_id', sortable: true },
        {key: 'from_warehouse_id', label: '仓库', sortable: true},
        // { key: 'to_warehouse_id', label: '入仓库', sortable: true },
        {key: 'is_related', label: '关联销售订单', sortable: true},
        {key: 'is_createorder', label: '创建退货申请单', sortable: true},
        // { key: 'create_time', label: '添加时间', sortable: true },
        {key: 'memo', label: '备注', sortable: true},
        // { key: 'creator', label: '添加人ID', sortable: true },
        // { key: 'update_time', label: '更新时间', sortable: true },
        // { key: 'updator', label: '更新人ID', sortable: true },
        {key: 'action', label: '操作'},
    ]

    const start = ref(1)
    const limit = ref(10)
    const limitOptions = [10, 25, 50, 100, 500, 1000]
    const listTotals = ref(0)
    const searchKey = ref('')
    const orderBy = ref('service_id')
    const isSortDirDesc = ref(true)
    const condition = ref({
        is_createorder: {"label": '否', code: 0}
    })

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
            to: limit.value * (start.value - 1) + localItemsCount,
            of: listTotals.value,
        }
    })

    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([start, limit, searchKey], () => {
        refetchData()
    })

    const searchList = (ctx, callback) => {
        const storeArrays = []
        const warehouseArrays = []
        const storeArray = isEmpty(condition.value.storeArray)?[]:condition.value.storeArray
        const warehouseArray = isEmpty(condition.value.warehouseArray)?[]:condition.value.warehouseArray
        for (let i = 0; i < storeArray.length; i++) {
            storeArrays.push(storeArray[i].value)
        }
        for (let i = 0; i < warehouseArray.length; i++) {
            warehouseArrays.push(warehouseArray[i].value)
        }
        store
            .dispatch('serviceorder/search', {
                search_key: searchKey.value,
                start: start.value,
                limit: limit.value,
                order_by: orderBy.value,
                order_desc: isSortDirDesc.value === true ? 'desc' : '',
                channel_id: condition.value.channelId,
                source: condition.value.source == null ? null : condition.value.source.code,
                types: condition.value.type == null ? null : condition.value.type.code,
                outServiceDate: condition.value.out_service_date,
                outSalesTime: condition.value.out_sales_time,
                createTimes: condition.value.create_time,
                out_product_id: condition.value.out_product_id,
                out_product_name: condition.value.out_product_name,
                product_id: condition.value.product_id,
                product_name: condition.value.product_name,
                is_related: condition.value.is_related == null ? null : condition.value.is_related.code,
                is_createorder: condition.value.is_createorder == null ? null : condition.value.is_createorder.code,
                creator: condition.value.creator,
                ourcompany_id: condition.value.searchOurCompanyId,
                batchno: condition.value.batchno,
                company_id: condition.value.companyName == null?null:condition.value.companyName.value,
                storeIds: storeArrays.join(','),
                warehouseIds: warehouseArrays.join(','),
            })
            .then(response => {
                const data = response.data.data
                const list = data.ext.list
                listTotals.value = data.ext.totals
                for (let i = 0; i < list.length; i++) {
                    if (list[i].from_warehouse_id != null) {
                        let WI = list[i].from_warehouse_id.split(",")
                        let WIName = ''
                        for (let j = 0; j < WI.length; j++) {
                            WIName += getCodeLabel("warehouse", WI[j]) + ","
                        }
                        list[i].fromWarehouseName = WIName

                    }

                }
                callback(list)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: '售后订单列表获取错误',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals: listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,
        condition,
        // Extra Filters
    }
}
